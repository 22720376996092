import React from "react";
import { Badge, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faSortNumericAsc, faSortNumericDesc, faUserPolice } from "@fortawesome/pro-light-svg-icons";
import { formatDate } from "../../../utils/date";
import Highlighter from "../../../components/highlighter/Highlighter";
import usePermissions from "../../../hooks/usePermissions";
import { parseAsHTML } from "../../../utils/string";
import { useAppSelector } from "../../../app/hooks";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { getNarrativeStyleNormalizationClass } from "../../../utils/helpers";

const NarrativeItem = ({
    narrative,
    textSummarization,
    hideSummarization = false,
}: {
    narrative: any;
    textSummarization: any;
    hideSummarization?: boolean;
}) => {
    const user = useAppSelector(({ user }) => user.userObject);
    const [expand, setExpand] = React.useState(!textSummarization);
    const { author, body, created_at, narrative_type } = narrative;
    let summary = textSummarization?.highlight?.summary
        ? textSummarization.highlight.summary
        : textSummarization?.summary
        ? textSummarization.summary
        : "";

    if (body && summary && body === body.toUpperCase()) {
        summary = summary.toUpperCase();
    }

    summary = parseAsHTML(summary);

    const isOfficerNarrative = narrative_type === "officer";

    const date = formatDate(created_at);

    const sanitizedNarrativeJSX = parseAsHTML(body || "");

    return (
        <ListGroup.Item className={isOfficerNarrative ? "border border-primary rounded" : ""}>
            <h3 className="d-flex justify-content-between fw-normal mb-1" data-testid="officer-name">
                <Highlighter>{author}</Highlighter>
                {isOfficerNarrative && (
                    <Badge pill bg="primary">
                        <FontAwesomeIcon icon={faUserPolice} />
                        <span className="ms-2">Officer</span>
                    </Badge>
                )}
            </h3>
            <p className="fw-normal fst-italic">
                <Highlighter>{date}</Highlighter>
            </p>
            <div className="mb-1" style={{ whiteSpace: "pre-line" }}>
                {hideSummarization ? (
                    <div>
                        <h4>
                            <u>Narrative</u>
                        </h4>
                        <div>{sanitizedNarrativeJSX}</div>
                    </div>
                ) : expand ? (
                    <div>
                        <h4 className="cursor-pointer" onClick={() => setExpand(false)}>
                            <FontAwesomeIcon icon={faAngleDown} />
                            &nbsp;<u>Narrative</u>
                        </h4>
                        <div className={getNarrativeStyleNormalizationClass(user?.agency_shortname)}>{sanitizedNarrativeJSX}</div>
                    </div>
                ) : (
                    <div>
                        <h4 className="cursor-pointer" onClick={() => setExpand(true)}>
                            <FontAwesomeIcon icon={faAngleRight} />
                            &nbsp;<u>Summary</u>{" "}
                            {narrative.hasHighlight && (
                                <Badge className="ms-4" bg="warning">
                                    <span className="text-dark">Contains Match</span>
                                </Badge>
                            )}
                        </h4>
                        {!!summary ? <Highlighter>{summary}</Highlighter> : "No Summary, expand to see full narrative."}
                    </div>
                )}
            </div>
        </ListGroup.Item>
    );
};

const BaseNarratives = ({ narratives, textSummarizations }: { narratives: any; textSummarizations: any }) => {
    const { hasPermission } = usePermissions();
    const { incidentText } = useAgencySettings();
    const [sortDescending, setSortDescending] = React.useState<boolean>(true);
    let sortedNarratives: any[] = [];
    let officerNarrative = null;

    const sortFn = (narrative1: any, narrative2: any) => {
        return sortDescending
            ? narrative1.created_at <= narrative2.created_at
                ? 1
                : -1
            : narrative1.created_at > narrative2.created_at
            ? 1
            : -1;
    };

    if (Array.isArray(narratives)) {
        sortedNarratives = [...narratives];
        const officerIndex = sortedNarratives.findIndex((narrative) => narrative.narrative_type === "officer");
        if (officerIndex > -1) {
            officerNarrative = sortedNarratives[officerIndex];
            sortedNarratives.splice(officerIndex, 1);
        }
        sortedNarratives.sort(sortFn);
    }

    // Find the text summary matching the given narrative id
    const findTextSummaryForNarrative = (narrativeId?: string) => textSummarizations?.find((summary: any) => summary?.id === narrativeId);

    return sortedNarratives.length || officerNarrative ? (
        <React.Fragment>
            <Button
                variant="secondary"
                size="sm"
                className="mt-3 mb-3 text-nowrap rounded-pill"
                onClick={() => setSortDescending(!sortDescending)}
            >
                <FontAwesomeIcon icon={sortDescending ? faSortNumericDesc : faSortNumericAsc} />
                <span className="ms-2">Date {sortDescending ? "descending" : "ascending"}</span>
            </Button>
            <ListGroup variant="flush">
                {officerNarrative && (
                    <NarrativeItem
                        key={officerNarrative?.narrativeId}
                        narrative={officerNarrative}
                        textSummarization={findTextSummaryForNarrative(officerNarrative?.narrativeId)}
                        hideSummarization={!hasPermission("view_narrative_summaries")}
                    />
                )}
                {sortedNarratives.map((narrative) => (
                    <NarrativeItem
                        key={narrative?.narrativeId}
                        narrative={narrative}
                        textSummarization={findTextSummaryForNarrative(narrative?.narrativeId)}
                        hideSummarization={!hasPermission("view_narrative_summaries")}
                    />
                ))}
            </ListGroup>
        </React.Fragment>
    ) : (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
            This {incidentText} does not currently have any narratives associated with it.
        </div>
    );
};

export default BaseNarratives;
