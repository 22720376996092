import React from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setUserInfo, setUserObject } from "../../app/slices/user/userSlice";
import ErrorBoundary from "./errorboundary/ErrorBoundary";
import { useGetUserDetailsQuery, useGetUserInfoQuery } from "../../api/api";
import LoadingModal from "../../components/LoadingModal";
import { getRefreshToken } from "../../utils/token";
import usePermissions from "../../hooks/usePermissions";

/**
 * Ensures the user is authenticated on routes that are behind login.  If the app is in maintenance mode for
 * a group, the maintenance page will be presented for that group.  If the user is coming from a deep link (i.e: a link
 * in an email) and they are not logged in, the link will be saved in the userSlice and the user redirected to login.
 * Upon successful login, the user will be taken to the deep linked page.
 */

const ProtectedRoute = ({ children }: any) => {
    const user = useAppSelector(({ user }) => user);
    const authenticated = user.isLoggedIn && !!getRefreshToken() && !!user.userObject;
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();

    if (hasPermission("use_nextfe_search") && pathname === "/app") {
        window.location.assign("/search");
    }

    // FEv2 User data for the current user
    const { data: legacyUserDetailsResponse, isError, isFetching } = useGetUserDetailsQuery({});
    // Next FE style user data for controlling multi-agency access
    const { data: userInfoResponse, isFetching: isUserInfoFetching, isError: userInfoHasError } = useGetUserInfoQuery({});

    // Navigate to maintenance page if agency is in maintenance mode
    React.useEffect(() => {
        if (!isFetching && !isUserInfoFetching) {
            if (!isError && legacyUserDetailsResponse) {
                dispatch(setUserObject(legacyUserDetailsResponse?.results));
            }

            if (!userInfoHasError && userInfoResponse) {
                dispatch(setUserInfo(userInfoResponse?.results));
            }

            if (pathname !== "/app/maintenance" && !!user.userObject?.agency_maintenance_mode) {
                navigate("/app/maintenance");
            }
        }
    }, [
        pathname,
        navigate,
        authenticated,
        dispatch,
        searchParams,
        user.userObject?.agency_maintenance_mode,
        isFetching,
        isError,
        legacyUserDetailsResponse,
        userInfoResponse,
        isUserInfoFetching,
        userInfoHasError,
    ]);

    return children ? (
        children
    ) : (
        <ErrorBoundary errorComponentVariant="page">
            <LoadingModal show={isFetching} />
            <Outlet context={[isFetching]} />
        </ErrorBoundary>
    );
};

export default ProtectedRoute;
