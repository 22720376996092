import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Row, Stack, Table } from "react-bootstrap";
import { formatDate } from "../../utils/date";
import { useGetUserSearchesQuery, useGetUserStatisticsQuery } from "../../api/api";
import LoadingModal from "../LoadingModal";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import { convertBodyParamNames, getUrl } from "../../utils/old_v1/helpers";
import { ASCENDING, DESCENDING } from "../../utils/old_v1/sort";
import { UserSearchBar } from "../usersearchbar/UserSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRangeFilter } from "../../pages/radar/filters/Filters";
import { faMap, faSearch, faSort, faSortDown, faSortUp } from "@fortawesome/pro-light-svg-icons";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

type SortConfig = {
    column: string;
    dir: string;
};

type OwnProps = {
    searches: any;
    users: any;
    page: number;
    setPage?: any;
    loading: boolean;
    setSort: Function;
    sort: SortConfig;
    setSelectedUser: Function;
    selectedUser: string;
};

const PAGE_SIZE = 20;

export const UserSearches = ({ searches, page, setPage, users, loading, setSort, sort, selectedUser, setSelectedUser }: OwnProps) => {
    const { mainSearchPath, canPerformNextFESearch } = useNextAwarePathname();
    const [totalPages, setTotalPages] = React.useState(0);
    const [recs, setRecs] = React.useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    React.useEffect(() => {
        if (!dateRange) {
            searchParams.set("dateRange", "30days");
            setSearchParams(searchParams);
        }
    }, [dateRange, searchParams, setSearchParams]);

    const close = () => {
        //not sure if this is what we want to do but just following
        //what we are already doing in the filters
        document.body.click();
    };

    React.useEffect(() => {
        if (searches && searches?.hits && searches?.hits?.hits) {
            setTotalPages(Math.ceil(searches?.hits?.total?.value / PAGE_SIZE));
            setRecs(searches?.hits?.hits);
        }
    }, [searches]);

    const getSortDir = (sortKey: string, defaultDir: string) => {
        if (sort?.column === sortKey) {
            return { column: sortKey, dir: sort?.dir === "asc" ? "desc" : "asc" };
        }
        return { column: sortKey, dir: defaultDir };
    };

    const getSortIcon = (column: string) => {
        if (sort?.column === column) {
            return sort?.dir === DESCENDING ? faSortDown : faSortUp;
        } else {
            return faSort;
        }
    };

    const ColumnHeader = ({ column, displayName }: { column: string; displayName: string }) => (
        <th onClick={() => setSort(getSortDir(column, ASCENDING))} className="cursor-pointer">
            {displayName}
            <FontAwesomeIcon icon={getSortIcon(column)} className="ms-2" />
        </th>
    );

    const HistoryItemContent = ({ isMapSearch, text }: { isMapSearch: boolean; text: string }) => {
        return (
            <>
                <FontAwesomeIcon icon={isMapSearch ? faMap : faSearch} size="sm" className="me-2" />
                {text}
            </>
        );
    };

    return (
        <>
            <Row>
                <Stack direction="horizontal" gap={3}>
                    <UserSearchBar users={users} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                    <DateRangeFilter close={close} dateRange={dateRange} startDate={startDate} endDate={endDate} />
                </Stack>
            </Row>
            <Row>
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <ColumnHeader column="user_last_name.keyword" displayName="Name" />
                                <ColumnHeader column="source" displayName="Source" />
                                <ColumnHeader column="body.search_for.keyword" displayName="Search Text" />
                                <ColumnHeader column="saved_date" displayName="Search Date" />
                            </tr>
                        </thead>
                        <tbody>
                            {recs &&
                                recs?.length > 0 &&
                                recs?.map((search: any) => {
                                    const name = `${search?._source?.user_last_name}, ${search?._source?.user_first_name}`;
                                    const isMapSearch = search?._source.body?.is_map === "true";

                                    if (search?._source && search?._source?.body) {
                                        const queryOnlyParams = new URLSearchParams({ query: search?._source?.body?.search_for || "" });

                                        return (
                                            <tr key={search?._source.id}>
                                                <td>{name}</td>
                                                <td>{search?._source?.source || ""}</td>
                                                <td>
                                                    {!isMapSearch && canPerformNextFESearch ? (
                                                        <a href={`${mainSearchPath}?${queryOnlyParams.toString()}`}>
                                                            <HistoryItemContent
                                                                isMapSearch={false}
                                                                text={search?._source?.body?.search_for || ""}
                                                            />
                                                        </a>
                                                    ) : (
                                                        <Link
                                                            to={getUrl(
                                                                {
                                                                    body: convertBodyParamNames(search?._source?.body),
                                                                },
                                                                isMapSearch
                                                            )}
                                                        >
                                                            <HistoryItemContent
                                                                isMapSearch={isMapSearch}
                                                                text={search?._source?.body?.search_for}
                                                            />
                                                        </Link>
                                                    )}
                                                </td>
                                                <td>{formatDate(search?._source?.saved_date)}</td>
                                            </tr>
                                        );
                                    } else {
                                        return [];
                                    }
                                })}
                            {!loading && (!recs || recs.length === 0) && (
                                <tr>
                                    <td className="border-0" colSpan={4}>
                                        No Results
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {totalPages > 0 && (
                <Row>
                    <Col xs={12} className="pt-4">
                        <FMPaginationNonLinks page={page} totalPages={totalPages} setPage={setPage} pagesDisplayCount={5} />
                    </Col>
                </Row>
            )}
            <LoadingModal show={loading} />
        </>
    );
};

/**
 * Redux connected component for the UserSearches
 * @constructor
 */
const ConnectedUserSearches = () => {
    const initialSort = { column: "saved_date", dir: DESCENDING };
    const [page, setPage] = React.useState<number>(1);
    const [sort, setSort] = React.useState<SortConfig>(initialSort);
    const [selectedUser, setSelectedUser] = React.useState<any>(undefined);
    const [searchParams] = useSearchParams();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const { data: searches, isLoading: searchesLoading } = useGetUserSearchesQuery({
        sort: { [sort.column]: sort.dir },
        page,
        pageSize: PAGE_SIZE,
        selectedUser: selectedUser?.email,
        dateRange,
        startDate,
        endDate,
    });
    const { data, isLoading } = useGetUserStatisticsQuery({});

    const selectPage = (pageNumber: number) => {
        if (page !== pageNumber) {
            setPage(pageNumber);
        }
    };

    const setUserFilter = (user: any) => {
        setPage(1);
        setSelectedUser(user);
    };

    return (
        <UserSearches
            users={data}
            searches={searches}
            page={page}
            setPage={selectPage}
            loading={(searches && searchesLoading) || isLoading}
            setSort={setSort}
            sort={sort}
            selectedUser={selectedUser}
            setSelectedUser={setUserFilter}
        />
    );
};

export default ConnectedUserSearches;
